@use 'actimo';
@use 'social-ui';
@use 'node_modules/photoswipe/src/css/main';

// Material styles
@use '@angular/material/prebuilt-themes/indigo-pink';

// todo: remove this when we have a proper solution for the toast
// added because the overlay on toast is blocking click on top navigation bar
.cdk-overlay-pane {
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }
}
// keeps modals underneath nav bar
body.ao-fit-modals-to-nav-desktop {
  .cdk-overlay-container {
    top: 52px;
  }
}

.safari.desktop .cdk-overlay-container {
  z-index: 10001;
}